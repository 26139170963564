<template>
  <nav id="sidebar"
       :class="{ show: bottomBurger }"
       @keyup.esc="closeVideo"
       tabindex="0"
       v-click-outside="closeVideo">
    <div class="sub-sidebar d-md-block d-none">
      <div class="sidebar-group text-center d-md-block d-none">
        <a class="logo mx-auto"
           @click="logoClicked">
          <img src="/images/logo_call.png" class="sdbr-logo"/>
        </a>
      </div>
      <div class="sidebar-group pb-0 mb-0"
           v-if="!homework && host && view === 'lesson'"
           v-click-outside="clickOutsideStudentSelect">
        <a class="sidebar-item"
           :title="$t('student_responses')"
           @click="showStudentsSelect = !showStudentsSelect">
          <div v-if="selectedUserModel" class="student-icon-selected">
            <LetterIcon
                :obj="selectedUserModel"
                :title="selectedUserModel.name"
                class="student-icon ml-0 sidebar-icon"/>
          </div>
          <GraduateSvg v-else/>
        </a>
      </div>

      <div class="sidebar-group sidebar-group__bordered"
           v-if="!homework && host && view === 'lesson' && lesson && lesson.lesson_type == 'lesson'"
           v-click-outside="clickOutsideShowRoom">
        <a class="sidebar-item"
           @click="$bvModal.show('subrooms-modal')"
           :title="$t('rooms')">
          <UserSvg/>
        </a>
        <Subrooms :passedStudents="students"
                  :usersdata="usersdata"/>
      </div>

      <div class="sidebar-group pb-0 mb-0">
        <div v-if="view != 'selfstudy' || bot" class="position-relative">
          <a class="sidebar-item"
             :title="$t('chat')"
             @click="chatShow = !chatShow">
            <ChatSvg/>
          </a>
          <span v-if="newMessage" class="bubble"></span>
        </div>
        <div>
          <a class="sidebar-item"
             :title="$t('vocabulary')"
             @click="vocShow = !vocShow">
            <VerbsSvg/>
          </a>
        </div>
        <div>
          <a class="sidebar-item"
             :title="$t('verbs')"
             @click="verbsShow = !verbsShow">
            <ListSvg/>
          </a>
        </div>
      </div>

      <div class="sidebar-group pb-0 mb-0">
        <div v-if="(host || homework || view == 'selfstudy') && material && material.audio_files && material.audio_files.length">
          <a class="sidebar-item"
             :title="$t('audio')"
             @click="audioShow = !audioShow">
            <AudioSvg/>
          </a>
        </div>
        <div v-if="material && cards && cards.length">
          <a class="sidebar-item"
             :title="$t('cards')"
             :class="{ active: lessonModesEnabled.includes('cards') }"
             @click="showCardsClicked">
            <ImageSvg/>
          </a>
        </div>
      </div>

      <div class="sidebar-group sidebar-group__bordered"
           v-if="!homework && showMediaControl">
        <a class="sidebar-item crossed"
           :title="muted ? $t('turn_on_microphone') : $t('mute_microfon')"
           :class="{ active: !muted}"
           @click="$emit('mute')">
          <MicSvg/>
        </a>
        <a class="sidebar-item crossed"
           :title="videomuted ? $t('turn_on_video') : $t('turn_off_video')"
           :class="{ active: !videomuted}"
           @click="$emit('videomute')">
          <CameraSvg/>
        </a>
      </div>
      <div class="sidebar-group sidebar-group__bordered"
           v-if="['lesson','selfstudy'].includes(view)">
        <a class="sidebar-item sidebar-item-mode"
           :title="$t('lesson')"
           :class="{ active: lessonModesEnabled.includes('slide') }"
           @click="$store.commit('toggleLessonMode', 'slide')">
          <SlidesSvg/>
        </a>
        <button class="sidebar-item sidebar-item-mode"
                v-if="['lesson'].includes(view)"
                :title="$t('blackboard')"
                @click="$store.commit('toggleLessonMode', 'blackboard')"
                :class="{ active: lessonModesEnabled.includes('blackboard') }">
          <PaletteSvg/>
        </button>
<!--        <a class="sidebar-item"-->
<!--           v-if="!linkchat && view === 'lesson'"-->
<!--           :title="$t('only_video')"-->
<!--           @click.stop="openVideo()">-->
<!--          <UserSvg/>-->
<!--        </a>-->
        <a class="sidebar-item sidebar-item-mode"
           v-if="presentation_files.length"
           :title="$t('presentation')"
           :class="{ active: lessonModesEnabled.includes('presentation') }"
           @click="$store.commit('toggleLessonMode', 'presentation')">
          <PresentationSvg/>
        </a>
        <a class="sidebar-item sidebar-item-mode"
           v-if="view === 'lesson' && host"
           @click="copyUnauthorizedLink"
           :title="$t('copy_unauthorized_link')">
          <LinkSvg class="link-svg"/>
        </a>
      </div>

      <!--      <div class="sidebar-group"  v-if="!homework">-->
      <!--        <a class="sidebar-item"-->
      <!--           @click="refresh"-->
      <!--           v-b-tooltip.hover.right-->
      <!--           :title="$t('refresh')">-->
      <!--          <RefreshSvg class="refresh-svg"/>-->
      <!--        </a>-->
      <!--        <a class="sidebar-item leave-item sidebar-item-red"-->
      <!--           v-b-tooltip.hover.right -->
      <!--           :title="$t('finish_lesson')"-->
      <!--           @click="$emit('stop')">-->
      <!--          End-->
      <!--        </a>-->
      <!--      </div>-->


      <div class="navigation-group" v-if="lessonModesEnabled.includes('slide')">
        <b-row class="no-gutters">
          <b-col>
            <button class="arrow-btn btn-left"
                    @click="previousSlideClicked">
              <LeftSvg/>
            </button>
          </b-col>
          <b-col>
            <button class="arrow-btn btn-right"
                    @click="nextSlideClicked">
              <RightSvg/>
            </button>
          </b-col>
        </b-row>
      </div>
    </div>

    <Chat v-show="chatShow"
          v-if="view !== 'selfstudy'"
          mode="chat"
          :socket="socket"
          :students="students"/>
    <Chat v-if="vocShow"
          mode="words"
          :socket="socket"
          :students="students"/>
    <AIChat v-if="view === 'selfstudy' && bot && chatShow"
            :bot="bot"
            @close="chatShow = false"/>
    <IrregularVerbsModal v-if="verbsShow"/>
    <LessonAudioModalCustom v-if="material && (socket || homework || view == 'selfstudy')"
                            v-show="audioShow"
                            :key="`audio-m-${material.id}`"
                            @showModal="audioShow = true"
                            :socket="socket"/>
    <div v-if="showStudentsSelect"
         class="sidebar-group pb-0 mb-0 student-responses-menu">
      <div class="dropdown-menu student-responses-dropdown show">
        <div class="dropdown-item font-weight-bolder text-muted">
          <b-row class="align-items-center">
            <b-col class="d-md-none d-block" cols="2">
              <a @click="showStudentsSelect = false">
                <BackSvg/>
              </a>
            </b-col>
            <b-col class="text-center text-md-left students-responses-title">
              {{ $t('choose_a_student') }}
            </b-col>
            <b-col class="d-md-none d-block text-right" cols="2">
              <a @click="showStudentsSelect = false; $store.commit('toggleShowMobileTopRight')">
                <CloseSvg/>
              </a>
            </b-col>
          </b-row>
        </div>
        <hr class="my-1"/>
        <div v-if="Object.keys(students).length < 2"
             class="text-center py-3">
          {{ $t('no_students') }}
        </div> <!-- less than 2, cause 1 is instructor -->
        <a v-for="(student, sind) in students"
           :key="'studentcounter'+sind"
           v-if="$store.state.user.id != student.id"
           class="dropdown-item pointer"
           :class="{ active: selectedUser == student.id }"
           @click="studentSelected(student)">
          <b-row class="align-items-center">
            <b-col cols="auto">
              <LetterIcon
                  :obj="student"
                  :title="student.name"
                  passedClass="font-weight-bolder"
                  class="student-icon ml-0 sidebar-icon"/>
            </b-col>
            <b-col class="text-left font-weight-bolder">
              {{ student.name }}
            </b-col>
          </b-row>
        </a>
        <hr class="mt-1 mb-0 d-md-block d-none"/>
        <a class="pointer dropdown-item font-weight-bolder d-md-block d-none" @click="cancelStudentSelection">{{ $t('cancel') }}</a>
      </div>
    </div>

    <div class="d-block d-md-none mobile-nav" :class="{
      expanded: mobileExpanded,
      keyboardOpened: isKeyboardOpen
    }">
      <a class="sidebar-item sidebar-item-mode more-item"
         :title="$t('more')"
         @click="mobileExpanded = !mobileExpanded">
        <div class="svg-wr"><MoreSvg/></div>
        <span>{{ $t('more') }}</span>
      </a>
      <div class="mobile-nav-content d-flex flex-wrap">
        <a v-if="['lesson','selfstudy'].includes(view)"
           class="sidebar-item sidebar-item-mode"
           :title="$t('lesson')"
           :class="{ active: lessonModesEnabled.includes('slide') }"
           @click="$store.commit('toggleLessonMode', 'slide'); mobileExpanded = false">
          <div class="svg-wr"><SlidesSvg/></div>
          <span>{{ $t('book') }}</span>
        </a>
        <a class="sidebar-item sidebar-item-mode"
           v-if="['lesson'].includes(view)"
           :title="$t('blackboard')"
           @click="$store.commit('toggleLessonMode', 'blackboard'); mobileExpanded = false"
           :class="{ active: lessonModesEnabled.includes('blackboard') || hostLessonModesEnabled.includes('blackboard') }">
          <div class="svg-wr"><PaletteSvg/></div>
          <span>{{ $t('board') }}</span>
        </a>
        <a class="sidebar-item sidebar-item-mode"
           v-if="presentation_files.length && ['lesson','selfstudy'].includes(view)"
           :title="$t('presentation')"
           :class="{ active: lessonModesEnabled.includes('presentation') }"
           @click="$store.commit('toggleLessonMode', 'presentation'); mobileExpanded = false">
          <div class="svg-wr"><PresentationSvg/></div>
          <span>{{ $t('pdf') }}</span>
        </a>
        <a v-if="view != 'selfstudy' || bot"
           class="sidebar-item"
           :title="$t('chat')"
           :class="{ active: chatShow }"
           @click="chatShow = !chatShow; mobileExpanded = false">
          <div class="svg-wr"><ChatSvg/></div>
          <span>{{ $t('chat') }}</span>
        </a>
        <a class="sidebar-item"
           v-if="(host || homework || view == 'selfstudy') && material && material.audio_files && material.audio_files.length"
           :title="$t('audio')"
           :class="{ active: audioShow }"
           @click="audioShow = !audioShow; mobileExpanded = false">
          <div class="svg-wr"><AudioSvg/></div>
          <span>{{ $t('audio') }}</span>
        </a>
        <a class="sidebar-item"
           v-if="material && cards && cards.length"
           :title="$t('cards')"
           :class="{ active: lessonModesEnabled.includes('cards') }"
           @click="showCardsClicked(); mobileExpanded = false">
          <div class="svg-wr"><ImageSvg/></div>
          <span>{{ $t('cards') }}</span>
        </a>
        <a class="sidebar-item"
           :title="$t('vocabulary')"
           :class="{ active: vocShow }"
           @click="vocShow = !vocShow; mobileExpanded = false">
          <div class="svg-wr"><VerbsSvg/></div>
          <span>{{ $t('words') }}</span>
        </a>
        <a class="sidebar-item"
           :title="$t('verbs')"
           :class="{ active: verbsShow }"
           @click="verbsShow = !verbsShow; mobileExpanded = false">
          <div class="svg-wr"><ListSvg/></div>
          <span>{{ $t('verbs') }}</span>
        </a>
      </div>
    </div>

    <div v-if="showMobileTopRight" class="mobile-top-right bg-white">
      <div class="mob-container pt-2">
        <b-row class="align-items-center">
          <b-col>
            <img src="/images/logo_h.png" class="logo" alt=""/>
          </b-col>
          <b-col cols="auto">
            <button class="call_room_btn pr-0"
                    @click="$store.commit('toggleShowMobileTopRight')">
              <CloseSvg/>
            </button>
          </b-col>
        </b-row>
      </div>
      <hr class="mt-2"/>

      <div class="mob-container">
        <div class="menu">
          <a @click="showStudentsSelect = !showStudentsSelect">
            <GraduateSvg class="svg-gray"/><span>{{ $t('student_responses') }}</span>
          </a>
          <a @click="$bvModal.show('subrooms-modal')">
            <UserSvg class="svg-gray"/><span>{{ $t('rooms') }}</span>
          </a>
        </div>
        <a class="btn-mob-grey d-block" @click="logoClicked">
          {{ $t('finish_lesson') }}
          <LogoutSvg/>
        </a>
      </div>

    </div>

  </nav>
</template>

<script>

import { mapState } from 'vuex'
import MicSvg from "@/assets/svg/mic.svg"
import UserSvg from '@/assets/svg/user.svg'
import CloseSvg from "@/assets/svg/close_gray.svg"
import CameraSvg from "@/assets/svg/camera.svg"
import GraduateSvg from "@/assets/svg/graduate.svg"
import PaletteSvg from "@/assets/svg/palette.svg"
import SlidesSvg from "@/assets/svg/slides.svg"
import ChatSvg from '@/assets/svg/chat.svg'
import ListSvg from '@/assets/svg/list.svg'
import VerbsSvg from '@/assets/svg/verbs.svg'
import ImageSvg from '@/assets/svg/image2.svg'
import AudioSvg from '@/assets/svg/audio5.svg'
import RightSvg from "@/assets/svg/right_s.svg"
import LeftSvg from "@/assets/svg/left_s.svg"
import PresentationSvg from "@/assets/svg/presentation2.svg"
import BackSvg from "@/assets/svg/back_2.svg"
import LogoutSvg from "@/assets/svg/logout.svg"
import MoreSvg from "@/assets/svg/more2.svg"
import LinkSvg from "@/assets/svg/link.svg"
import LetterIcon from '@/views/parts/general/LetterIcon'
import Subrooms from "../class/Subrooms"
import Chat from "../class/Chat"
import IrregularVerbsModal from "../class/IrregularVerbsModal"
import LessonAudioModalCustom from "../class/LessonAudioModalCustom"
import AIChat from "../specific/AIChat.vue"
// import VueResizable from 'vue-resizable'

export default {
  components: {
    AIChat,
    LessonAudioModalCustom,
    IrregularVerbsModal,
    Chat,
    Subrooms,
    LetterIcon,
    MicSvg,
    UserSvg,
    GraduateSvg,
    CameraSvg,
    PaletteSvg,
    SlidesSvg,
    ChatSvg,
    ListSvg,
    VerbsSvg,
    AudioSvg,
    ImageSvg,
    RightSvg,
    LeftSvg,
    PresentationSvg,
    CloseSvg,
    BackSvg,
    LogoutSvg,
    MoreSvg,
    LinkSvg,
    // MenuSvg
  },
  props: {
    muted: { type: Boolean },
    videomuted: { type: Boolean },
    view: { type: String, default: 'lesson' },
    lesson: { type: Object, default: null },
    students: {},
    linkchat: {},
    usersdata: {}
  },
  data() {
    return {
      showMediaControl: false,
      showStudentsSelect: false,
      showRooms: false,
      chatShow: false,
      vocShow: false,
      verbsShow: false,
      audioShow: false,
      cardsShow: false,
      mobileExpanded: false,
    }
  },
  created() {
    document.onkeydown = evt => {
      evt = evt || window.event;
      if (evt.keyCode === 27) {
        this.closeVideo()
      }
    };
    window.visualViewport.addEventListener('resize', this.keyboardListener)
  },
  beforeDestroy() {
    window.visualViewport.removeEventListener('resize', this.keyboardListener)
  },
  mounted() {},
  methods: {
    closeVideo() {
      this.$store.commit('setFullVideoOpen', false)
    },
    openVideo() {
      if (!this.fullVideoOpen) {
        this.$store.commit('setFullVideoOpen', true)
        this.$nextTick(() => {
          this.$emit('fullVideo')
        })
      } else {
        this.$store.commit('setFullVideoOpen', false)
      }
    },
    refresh() {
      location.reload()
    },
    showCardsClicked() {
      console.log('show cards')
      this.$store.commit('toggleLessonMode', 'cards')
    },
    studentSelected(student) {
      let peerId = null
      for(const u in this.usersdata) {
        if(this.usersdata[u].dbUserId == student.id) peerId = u
      }
      if(!peerId) return
      this.$parent.selectUser(student.id, peerId)
      this.showStudentsSelect = false
    },
    cancelStudentSelection() {
      this.$parent.selectUser(null, null, true)
      this.showStudentsSelect = false
    },
    clickOutsideStudentSelect() {
      if(!this.isMobile) this.showStudentsSelect = false
    },
    clickOutsideShowRoom() {
      this.showRooms = false
    },
    nextSlideClicked() {
      if (this.currentSlide === (this.currentSlides.length - 1)) {
        this.$store.commit('setFinishedLesson', true)
      }
      this.$store.commit('setSpecificSlide', Math.min((this.currentSlides.length - 1), (this.currentSlide + 1)))
      this.changeSlide()
    },
    previousSlideClicked() {
      if (this.finishedLesson) {
        this.$store.commit('setFinishedLesson', false)
        this.changeSlide()
        return
      }
      this.$store.commit('setSpecificSlide', Math.max(0, (this.currentSlide - 1)))
      this.changeSlide()
    },
    toslide(val) {
      this.$store.commit('setSpecificSlide', Math.min((this.currentSlides.length - 1), val))
      this.changeSlide()
    },
    changeSlide() {
      if (this.homework) {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        return
      }
      if (this.host || this.followed) {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        if(this.socket) {
          this.socket.emit('customevent', 'slidechanged', this.room, this.slide, this.finishedLesson)
        }
      }
    },
    logoClicked() {
      if(this.host || this.homework || this.view === 'selfstudy') {
        this.$emit('stop')
      }
    },
    keyboardListener() {
      const MIN_KEYBOARD_HEIGHT = 300 // N.B.! this might not always be correct
      const isMobile = window.innerWidth < 768
      this.$store.commit('setKeyboardOpen', isMobile && window.screen.height - MIN_KEYBOARD_HEIGHT > window.visualViewport.height)
    },
    async copyUnauthorizedLink() {
      const route = this.$router.resolve({
        name: 'UnauthorizedToLesson',
        params: { id: this.lesson.id, code: this.lesson.code }
      })
      await navigator.clipboard.writeText(`${window.location.origin}${route.href}`)
      this.$success(this.$t('copied'))
    }
  },
  computed: {
    ...mapState({
      bottomBurger:       state => state.call.bottomBurger,
      cards:              state => state.call.lessonCards,
      finishedLesson:     state => state.call.finishedLesson,
      followed:           state => state.call.followed,
      fullVideoOpen:      state => state.fullVideoOpen,
      homework:           state => state.call.isHomework,
      host:               state => state.call.host,
      lessonModesEnabled: state => state.call.lessonModesEnabled,
      room:               state => state.call.room,
      selectedUser:       state => state.call.selectedUser,
      showCards:          state => state.call.showCards,
      slide:              state => state.call.slide,
      slides:             state => state.call.slides,
      slidemode:          state => state.call.slidemode,
      hostLessonModesEnabled: state => state.call.hostLessonModesEnabled,
      material:           state => state.call.material,
      socket:             state => state.call.socket,
      newMessage:         state => state.call.newMessage,
      showMobileTopRight: state => state.call.showMobileTopRight,
      isMobile:           state => state.isMobile,
      isKeyboardOpen:     state => state.isKeyboardOpen,
      viewMode:           state => state.call.viewMode,
    }),
    selectedUserModel() {
      if(!this.selectedUser || !this.students) return null
      return Object.values(this.students).find(student => student.id == this.selectedUser)
    },
    currentSlide() {
      return this.slide[this.slidemode]
    },
    currentSlides() {
      return this.slides[this.slidemode]
    },
    canDraw() {
      return this.host || this.followed || this.viewMode !== 'lesson'
    },
    presentation_files() {
      return this.material?.presentation_files || []
    },
    bot() {
      return this.material?.bot
    }
  }
}
</script>

<style scoped lang="scss">
#sidebar {
  &:focus {
    outline: none;
  }
  .logo {
    display: inline-block;
    width: 48px;
    img {
      width: 100%;
    }
  }
  .navigation-group {
    width: 48px;
    margin: 0 auto;
    position: absolute;
    bottom: 20px;
    left: 12px;
  }
  z-index: 23;
  width: 72px;
  .student-responses-menu {
    top: 0;
    position: absolute !important;
    left: 72px;
  }
  .sub-sidebar {
    margin-top: 10px;
    position: relative;
    min-height: 100%;
    padding-bottom: 70px;

    &:focus {
      outline: none;
    }
  }
  .sidebar-group {
    position: relative;
    > div:not(:last-of-type) {
      .sidebar-item {
        margin-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        //height: 44px;
      }
    }
    > div:not(:first-of-type) {
      .sidebar-item {
        margin-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        //height: 44px;
      }
    }
    .sidebar-item {
      &.sidebar-item-mode {
        background: #C2C7D0;
        svg {
          fill: white;
        }
        &.active, &:hover {
          background: $text-themed;
          svg {
            fill: white;
          }
        }
      }
      &.crossed {
        &:not(.active) {
          background: transparent;
          svg {
            fill: $theme-red;
          }
        }
        &:after {
          content: "";
          width: 30px;
          height: 1px;
          background: $theme-red;
          position: absolute;
          top: 23px;
          left: 7px;
          transform-origin: center;
          transform: rotate(-45deg);
        }
      }
      &.op1 {
        opacity: 1;
      }
      .refresh-svg {
        width: 18px;
      }
      background: $gray-back2;
      opacity: 0.8;
      position: relative;
      border: none;
      display: block;
      border-radius: 16px;
      width: 48px;
      height: 48px;
      line-height: 48px;
      margin: 6px auto;
      cursor: pointer;
      fill: $text-gray3;
      &.sidebar-item-red {
        &:hover {
          text-decoration: none;
          background: $theme-red;
        }
        color: #fff;
        background: $bloody-red;
      }
      svg {
        fill: #C2C7D0;
      }
      &.active {
        &.crossed:after {
          content: none;
        }
      }
      &.active, &:hover {
        svg {
          fill: $text-themed;
        }
      }

    }
    .dropdown-menu {
      left: 100%;
      top: 0;
      min-width: 260px;
      .dropdown-item {
        line-height: inherit;
        height: auto;
        width: auto;
        padding-top: 6px;
        padding-bottom: 6px;
        @media(max-width: 768px) {
          font-size: 12px;
          padding: 8px 15px;
        }
        &.active {
          background: $border-color2;
        }
      }
      .student-icon {
        width: 32px;
        height: 32px;
        line-height: 32px;
      }
    }

    .student-icon {
      width: 48px;
      margin-left: 12px;
      @media(max-width: 768px) {
        width: 40px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .student-icon-selected {
      border: 2px solid #71DE8F;
      border-radius: 100%;
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      .student-icon {
        width: 39px;
      }
    }
  }

  .arrow-btn {
    background: #fff;
    border: none;
    width: 24px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &.btn-left {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &.btn-right {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .link-svg {
    width: 22px;
  }

  @media(max-width: 768px) {
    .sdbr-logo {
      width: 60%;
    }
  }
}
.mobile-top-right {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .logo {
    width: 50px;
  }
  .menu {
    a {
      display: block;
      padding: 10px 0;
      border-bottom: 1px solid #E8EFFA;
    }
    svg {
      width: 50px;
    }
  }
}


.bubble {
  border-radius: 100%;
  width: 6px;
  height: 6px;
  display: inline-block;
  background: $theme-red;
  position: absolute;
  top: 10px;
  right: 20px;
  animation: pulse 1.4s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(1);
    background: $theme-red;
  }
  70% {
    transform: scale(1.2);
    background: #a20505;
  }
}

@media(max-width: 768px) {
  .btn-mob-grey {
    background: #E6EAF2;
    border-radius: 10px;
    color: #5D6779;
    padding: 10px;
    position: absolute;
    bottom: 80px;
    width: calc(100% - 30px);
    text-align: center;
  }
  .students-responses-title {
    font-size: 16px;
    color: #000;
    font-weight: 600;
  }
  #sidebar {
    .student-responses-menu {
      left: 0;
      position: fixed !important;
      width: 100%;
      height: 100%;
      z-index: 1041;

    }
    .sidebar-group {
      .student-icon-selected {
        width: 40px;
        height: 40px;
        .student-icon {
          width: 34px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .dropdown-menu {
        .student-icon {
          width: 34px;
        }
      }
      .student-responses-dropdown {
        left: 0;
        border-radius: 0;
        padding-top: 0;
        border: none;
        width: 100%;
      }
    }
  }
}


@media(max-width: 768px) {
  #sidebar {
    width: 100% !important;
    top: auto;
    box-shadow: 0px -2px 13px -5px rgba(159, 163, 168, 0.25);
    z-index: 20;
    background: #fff;
    .sub-sidebar {
      display: flex;
      flex-direction: row;
      padding: 0;
      margin-top: 0;
    }
    .mobile-nav {
      transition: 0.1s ease-in all;
      position: relative;
      z-index: 5;
      background: white;
      border-top: 1px solid #eee;
      .mobile-nav-content {
        width: 80%;
      }
      &.keyboardOpened {
        display: none !important;
      }
      max-height: 64px;
      &.expanded {
        max-height: 128px;
      }
      a.sidebar-item {
        &.more-item {
          position: absolute;
          right: 0;
          top: 0;
        }
        line-height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25%;
        .svg-wr {
          height: 20px;
          display: flex;
          align-items: center;
        }
        svg {
          height: auto;
        }
        span {
          font-size: 10px;
          display: block;
          margin-top: 5px;
          color: #B3B7C0;
          text-transform: capitalize;
        }
        &.active {
          text-decoration: none;
          span {
            color: $text-themed;
          }
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

@media(max-height: 740px) {
  #sidebar {
    .sidebar-group {
      margin-bottom: 0;
    }
  }
}

@media(max-height: 660px) {
  #sidebar {
    .sidebar-group {
      padding-bottom: 0;
    }
  }
}

@media(max-height: 550px) {
  #sidebar {
    overflow-y: scroll;
  }
}
</style>
