var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"word-teaser"},[_c('div',{staticClass:"word-teaser-content"},[_c('div',{},[_c('b-row',{staticClass:"align-items-center"},[_c('b-col',{staticClass:"text-center mb-3",attrs:{"cols":"12"}},[_c('a',{staticClass:"pointer",on:{"click":function($event){$event.stopPropagation();return _vm.playAudio.apply(null, arguments)}}},[_c('AudioSvg',{staticClass:"svg-blue svg-icon-lg"})],1)]),_c('b-col',{attrs:{"cols":"12 mb-3"}},[(_vm.word.description)?_c('div',{staticClass:"word-description"},[_vm._v(" "+_vm._s(_vm.word.description)+" ")]):_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"word-teaser-img",attrs:{"src":_vm.word.image ? _vm.word.image : "/images/logo_h.png","alt":""}})])]),(['memorize'].includes(_vm.mode))?_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"word text-center title"},[_vm._v(" "+_vm._s(_vm.word.word)+" ")]),(_vm.word.transcription)?_c('div',{staticClass:"text-muted mt-2"},[_vm._v(" ["+_vm._s(_vm.word.transcription)+"] ")]):_vm._e()]):_vm._e(),(['assign'].includes(_vm.mode))?_c('b-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"response-box",class:{
            right: _vm.showAnswer && _vm.response && _vm.response.word === _vm.word.word,
            wrong: _vm.showAnswer && _vm.response && _vm.response.word !== _vm.word.word
          }},[_vm._v(" "+_vm._s(_vm.response ? _vm.response.word : '')+" ")])]):_vm._e(),(['letters'].includes(_vm.mode))?_c('b-col',{attrs:{"cols":"12"}},[_c('div',_vm._l((_vm.word.word),function(w,wind){return _c('span',{key:("let-" + wind),staticClass:"letter-blank",class:{
                    right: _vm.finished && _vm.filledLetters[wind] && w === _vm.filledLetters[wind].letter,
                    wrong: _vm.finished && _vm.filledLetters[wind] && w !== _vm.filledLetters[wind].letter 
                  },on:{"click":function($event){return _vm.dropLetter(wind)}}},[(_vm.filledLetters[wind])?[_vm._v(_vm._s(_vm.filledLetters[wind].letter))]:_vm._e()],2)}),0),_c('div',{staticClass:"mt-3"},_vm._l((_vm.shuffled),function(w,wind){return _c('span',{key:("let-r-" + wind),staticClass:"letter-drag",class:{ active: _vm.selectedLetter && _vm.selectedLetter.index === wind },on:{"click":function($event){return _vm.selectLetter(w, wind)}}},[(!_vm.filledLetters.filter(function (x) { return x && x.index === wind; }).length)?[_vm._v(" "+_vm._s(w)+" ")]:_vm._e()],2)}),0)]):_vm._e(),(['choose'].includes(_vm.mode))?_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',_vm._l((_vm.shuffled),function(w,wind){return _c('b-col',{key:("sh-r-" + wind),staticClass:"mb-10",attrs:{"cols":"6"}},[_c('div',{staticClass:"word-option",class:{
                      right: _vm.selectedWord && _vm.selectedWord.word === w.word && w.word === _vm.word.word,
                      wrong: _vm.selectedWord && _vm.selectedWord.word === w.word && w.word !== _vm.word.word      
                   },on:{"click":function($event){return _vm.selectWord(w)}}},[_c('span',{staticClass:"d-inline-block"},[_vm._v(_vm._s(w.word))])])])}),1)],1):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }